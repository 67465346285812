// eslint-disable-next-line max-params
const downloadAsset = async (assetId: string, signature: string, getSize?: boolean, start?: number, end?:number): Promise<Blob | any> => {
  const data = JSON.stringify({
    file: `${assetId}.zip`,
    token: signature,
    getSize: !!getSize,
    start: start ?? 0,
    end: end ?? 0,
  });

  let responseData;

  try {
    responseData = fetch(`${process.env.FIREBASE_API_URL}/asset-download`, {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      body: data,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/zip',
      },
    }).then((response) => {
      if (response.status === 201) {
        return Promise.resolve(response.json());
      }
      return Promise.resolve(response.blob());
    });
  } catch (error) {
    return Promise.reject(error);
  }
  return responseData;
};

export { downloadAsset };
