/* eslint-disable camelcase */

import { ethers } from 'ethers';
import { sign } from 'web3-token';

const personalSign = async (): Promise<string> => {
  let token: string;
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  const signer = provider.getSigner();
  try {
    token = await sign(async (msg) => await signer.signMessage(msg), {
      domain: 'lowesopenbuilder.com',
      statement: 'I accept the Terms and Conditions: https://nft.lowesopenbuilder.com/terms/',
      expires_in: '1 day',
    });
  } catch (error) {
    return Promise.reject(error);
  }

  return Promise.resolve(token);
};

export { personalSign };
