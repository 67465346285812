import React, { useCallback, useEffect, useRef, useState } from 'react';

const useOutsideClickClose = (onClose: () => void): React.RefObject<HTMLDivElement> => {
  const [isLoaded, setIsLoaded] = useState(false);
  const elementRef = useRef<HTMLDivElement>(null);

  const checkClickInside = (target: EventTarget | null, container?: Element) =>
    container?.contains(target as Node);

  const handleClick = useCallback(
    (event: { target: EventTarget | null }) => {
      const currentElement = elementRef.current;
      if (!currentElement) {
        return;
      }

      const boundingClientRect = currentElement.getBoundingClientRect();
      if (boundingClientRect.width === 0 || boundingClientRect.left < 0) {
        return;
      }
      if (!checkClickInside(event.target, currentElement) && isLoaded) {
        onClose();
      }
      setIsLoaded(true);
    },
    [onClose, isLoaded]
  );

  useEffect(() => {
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [handleClick]);

  return elementRef;
};

export default useOutsideClickClose;
