import React, { useState } from 'react';
import styled from 'styled-components';
import screens from '../@style/screens';
import Button from './ui/button';
import { CERULEAN } from '../@style/colors';
import { convertToBgImage } from 'gbimage-bridge';
import PlayButtonSVG from '../images/play-button.svg';
import BackgroundImage from 'gatsby-background-image';
import ReactPlayer from 'react-player';
import { BrowserView, MobileView } from 'react-device-detect';
import moment from 'moment';

interface StarterPackProps {
  title: string;
  description: string;
  isLoggedUser: boolean;
  img: Queries.GetAssetsQuery['allAssets']['nodes'][0]['imageFile'];
  videoUrl: string;
  isAvailabled: boolean;
  commingDate?: string;
  handleOnClick: () => void;
  customHeight?: string;
}

// styles
const Wrapper = styled.div<Pick<StarterPackProps, 'isLoggedUser'>>`
  display: flex;
  flex-direction: column;
  width: 85vw;
  @media ${screens.laptop} {
    width: 100%;
    ${(props) => (props.isLoggedUser ? 'min-height: calc((((50vw - 5rem) * 0.9) - 1rem) * 0.5625 + 15.5rem)' : '')}
  }
  @media ${screens.laptopXL} {
    ${(props) => (props.isLoggedUser ? 'min-height: calc((((50vw - 5rem) * 0.9) - 1rem) * 0.5625 + 14rem)' : '')}
  }
`;

type PromoWrapperProps = Pick<StarterPackProps, 'isAvailabled'>;
const PromoWrapper = styled.div<PromoWrapperProps>`
  position: relative;
  width: 85vw;
  height: calc(85vw * 0.5625);
  border-radius: 20px;
  overflow: hidden;
  border: 3px solid ${CERULEAN};
  cursor: ${(props) => (props.isAvailabled ? 'pointer' : 'not-allowed')};
  @media ${screens.laptop} {
    width: 100%;
    height: calc((((50vw - 5rem) * 0.9) - 1rem) * 0.5625);
  }
`;

const PlayButtonSVGWrapper = styled(PlayButtonSVG)`
  width: 30px;
  height: 30px;
  position: absolute;
  z-index: 10;
  right: 10px;
  bottom: 10px;
  cursor: pointer;
  @media ${screens.laptop} {
    width: 45px;
    height: 45px;
  }
`;

const ComingSoon = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 10;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  align-items: center;
  justify-content: center;
  h1 {
    font-weight: 700;
    font-size: 26px;
    @media ${screens.laptop} {
      font-size: 40px;
    }
  }
  p {
    font-weight: 500;
    font-size: 17px;
    @media ${screens.laptop} {
      font-size: 24px;
    }
  }
`;

const Title = styled.h3`
  font-size: 20px;
  font-weight: bold;
  margin: 20px 0 10px 0;
  @media ${screens.mobileL} {
    font-size: 24px;
  }
`;

interface DescriptionProps {
  textAlign: string;
  marginBottom?: string;
}

const Description = styled.p<DescriptionProps>`
  font-size: 17px;
  text-align: ${(props) => props.textAlign};
  font-weight: 400;
  margin-bottom: ${(props) => props.marginBottom || '0px'};
  @media ${screens.mobileL} {
    font-size: 20px;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: auto;
  span {
    font-size: 12px;
    margin-left: 20px;
  }
`;

const StarterPack: React.FC<StarterPackProps> = ({
  title,
  description,
  isLoggedUser,
  img,
  videoUrl,
  isAvailabled,
  commingDate,
  handleOnClick,
}) => {
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const image = img?.childImageSharp?.gatsbyImageData;
  const bgImage = convertToBgImage(image);

  return (
    <Wrapper isLoggedUser={isLoggedUser}>
      <PromoWrapper isAvailabled={isAvailabled}>
        {!isAvailabled && (
          <ComingSoon>
            <h1>Coming Soon</h1>
            <p>{moment(commingDate, 'MM/DD/YYYY').format('MMMM DD, YYYY')}</p>
          </ComingSoon>
        )}
        <BrowserView style={{ width: '100%', height: '100%' }}>
          <div
            style={{ width: '100%', height: '100%' }}
            onMouseOver={() => {
              if (isAvailabled) {
                setIsPlaying(true);
              }
            }}
            // onMouseLeave={() => {
            //   if (isAvailabled) {
            //     setIsPlaying(false);
            //   }
            // }}
          >
            {isAvailabled ? (
              <ReactPlayer
                width="100%"
                height="100%"
                url={videoUrl}
                muted={true}
                playsinline={true}
                onEnded={() => setIsPlaying(false)}
                playing={isPlaying}
              />
            ) : (
              <BackgroundImage
                preserveStackingContext={true}
                style={{
                  display: 'block',
                  width: '100%',
                  height: '100%',
                  backgroundSize: 'cover',
                  opacity: isAvailabled ? '1' : '0.5',
                }}
                {...bgImage}
              />
            )}
          </div>
        </BrowserView>
        <MobileView style={{ width: '100%', height: '100%' }}>
          {isAvailabled && !isPlaying && (
            <PlayButtonSVGWrapper onClick={() => setIsPlaying(true)} />
          )}
          {isAvailabled ? (
            <ReactPlayer
              width="100%"
              height="100%"
              url={videoUrl}
              muted={true}
              playsinline={true}
              onEnded={() => setIsPlaying(false)}
              playing={isPlaying}
            />
          ) : (
            <BackgroundImage
              preserveStackingContext={true}
              style={{
                display: 'block',
                width: '100%',
                height: '100%',
                backgroundSize: 'cover',
                opacity: isAvailabled ? '1' : '0.5',
              }}
              {...bgImage}
            />
          )}
        </MobileView>
      </PromoWrapper>
      <Title>{title}</Title>
      <Description textAlign="left">{description}</Description>
      {isLoggedUser && isAvailabled && (
        <Row>
          <Button type="internal" buttonStyle="fit" onClick={handleOnClick}>
            Download Now
          </Button>
        </Row>
      )}
    </Wrapper>
  );
};

export default StarterPack;
