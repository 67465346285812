import React, { useCallback, useEffect, useRef } from 'react';

const useAutoClose = (onClose: () => void): React.RefObject<HTMLDivElement> => {
  const elementRef = useRef<HTMLDivElement>(null);

  const escFunction = useCallback(
    (event: { keyCode: number }) => {
      if (event.keyCode === 27) {
        onClose();
      }
    },
    [onClose]
  );

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false);
    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, [escFunction]);

  return elementRef;
};

export default useAutoClose;
