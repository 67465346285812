import { Asset } from '../constants/assets';

const getAssets = async (signature: string): Promise<Asset[]> => {
  let assets: Asset[];
  try {
    assets = await fetch(`${process.env.FIREBASE_API_URL}/assets`, {
      method: 'POST',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        token: signature,
      }),
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        return data.assets;
      });
  } catch (error) {
    return Promise.reject(error);
  }

  return Promise.resolve(assets);
};

export { getAssets };
