import React, { useState } from 'react';
import styled from 'styled-components';
import { graphql, navigate, PageProps, useStaticQuery } from 'gatsby';
import Layout from '../components/ui/layout';
import Input from '../components/ui/input';
import screens from '../@style/screens';
import SEO from '../components/seo';
import RedemptionFrame from '../components/redemptionFrame';
import Grid from '../components/ui/grid';
import StarterPack from '../components/starterPack';
import { isLoggedIn, getUserSignature } from '../api/auth.service';
import useAutoClose from '../hooks/useAutoClose';
import VideoPlayer from '../components/ui/videoPlayer';
import PopUp from '../components/ui/popUp';
import MetaMaskSVG from '../images/metamask-logo.svg';
import { Message, SOMETHING_WENT_WRONG, WALLET_IS_NOT_CONNECTED } from '../constants/messages';
import { saveAs } from 'file-saver';
import { downloadAsset } from '../api/downloadAsset.service';
import getFileChunckPromisesArray from '../utils/fileChuck';
import Progress from '../components/ui/progress';
import Footer from '../components/footer';
import Header from '../components/header';

const CollectionsWrapper = styled.div`
  width: 100%;
  min-height: 95vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  @media ${screens.laptop} {
    min-height: unset;
    width: 90%;
    padding-bottom: 50px;
  }
`;

const CollectionsTitle = styled.h1`
  font-size: 22px;
  text-align: center;
  font-weight: bold;
  margin: 20px 0;
  @media ${screens.tablet} {
    font-size: 33px;
    margin: 45px 0 20px 0;
  }
`;

const HeaderSubTitle = styled.h1`
  font-size: 17px;
  text-align: center;
  font-weight: 500;
  margin: 0 0 30px 0;
  padding-top: 40px;
  @media ${screens.tablet} {
    margin: 0 0 50px 0;
    padding-top: 50px;
    max-width: 60%;
  }
  @media ${screens.laptop} {
    font-size: 20px;
  }
  @media ${screens.laptopL} {
    margin: 0 0 40px 0;
  }
`;

const Wrapper = styled.div`
  display: relative;
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;
`;

const MetaMaskSVGWrapper = styled(MetaMaskSVG)`
  width: 32px;
  height: 32px;
  margin-top: 30px;
`;

const GetAssets = graphql`
  query GetAssets {
    allAssets {
      nodes {
        alternative_id
        id
        title
        description
        imageFile {
          childImageSharp {
            gatsbyImageData(quality: 90, layout: FULL_WIDTH)
          }
        }
        video
        available
        commingDate
      }
    }
  }
`;

const THE_AIRDROP_IS_OVER =
  'The airdrop is over. If you claimed one of our exclusive NFTs, check your MetaMask wallet and use it to download any of the exclusive Decentraland collections below. Sign in with your MetaMask wallet to start downloading.';
const CONGRATULATIONS =
  'Congratulations! We were able to verify your NFT. You can now use it to access and download the content below. Happy building!';

const CollectionsPage: React.FC<PageProps> = () => {
  const [currentVideoUrl, setCurrentVideoUrl] = useState<string>();
  const [currentMessage, setCurrentMessage] = useState<Message>(SOMETHING_WENT_WRONG);
  const [showPopUp, setShowPopUp] = useState<boolean>(false);
  const [isLoggedUser, setIsLoggedUser] = useState<boolean>(isLoggedIn());
  const { allAssets } = useStaticQuery<Queries.GetAssetsQuery>(GetAssets);

  const autoCloseRef = useAutoClose(() => {
    setCurrentVideoUrl('');
  });

  const handleDownloadAsset = async (assetId: string, assetName: string) => {
    const signature = isLoggedUser ? getUserSignature() : '';
    if (!signature) {
      setCurrentMessage(WALLET_IS_NOT_CONNECTED);
      setShowPopUp(true);
      return;
    }

    setCurrentMessage({
      code: 6,
      title: assetName,
      description: 'Downloading…',
      loading: true,
    });
    setShowPopUp(true);
    const { size } = await downloadAsset(assetId, signature, true).catch(() => {
      setCurrentMessage(SOMETHING_WENT_WRONG);
      setShowPopUp(true);
    });

    const requests = getFileChunckPromisesArray(size, assetId, signature);
    await Promise.all(requests)
      .then((blobs) => {
        const blobData = new Blob(blobs, { type: 'application/zip' });
        saveAs(blobData, `${assetId}.zip`);
        setShowPopUp(false);
      })
      .catch(() => {
        setCurrentMessage(SOMETHING_WENT_WRONG);
        setShowPopUp(true);
      });
  };

  const handleOnClick = (id: string | null, name: string | null) => {
    if (id && name) {
      handleDownloadAsset(id, name);
    }
  };

  return (
    <>
      <SEO title="Lowe’s Open Builder | Collections" />
      <Header />
      <Wrapper>
        <Layout>
          <CollectionsWrapper>
            <RedemptionFrame
              isLoggedUser={isLoggedUser}
              description={isLoggedUser ? CONGRATULATIONS : THE_AIRDROP_IS_OVER}
              setCurrentMessage={setCurrentMessage}
              setShowPopUp={setShowPopUp}
              setIsLoggedUser={() => setIsLoggedUser(!isLoggedUser)}
            />
            <CollectionsTitle>Exclusive Decentraland Collections</CollectionsTitle>
            <Grid>
              {allAssets.nodes.map((node) => (
                <StarterPack
                  key={node.id}
                  title={node.title ?? ''}
                  description={node.description ?? ''}
                  isLoggedUser={isLoggedUser}
                  img={node.imageFile}
                  videoUrl={node.video ?? ''}
                  isAvailabled={node.available ?? false}
                  commingDate={node.commingDate ?? ''}
                  handleOnClick={() => handleOnClick(node.alternative_id, node.title)}
                />
              ))}
            </Grid>
            <HeaderSubTitle>
              If you weren’t able to claim an NFT you can still browse and download over 500 3D
              objects to help you create even more possibilities in the metaverse.
            </HeaderSubTitle>
            <Input
              id="search-input"
              placeholder="Search our 3D product catalog"
              onEnter={(phrase?: string) => navigate('/search', { state: { phrase: phrase } })}
              showBackground={false}
            />
          </CollectionsWrapper>
        </Layout>
      </Wrapper>
      <Footer/>
      {currentVideoUrl && (
        <div ref={autoCloseRef}>
          <VideoPlayer url={currentVideoUrl} onClose={() => setCurrentVideoUrl('')} />
        </div>
      )}
      {showPopUp && (
        <div ref={autoCloseRef}>
          <PopUp title={currentMessage.title} onClose={() => setShowPopUp(false)}>
            <p>{currentMessage.description}</p>
            {currentMessage.metaMaskLogo && (
              <p>
                <MetaMaskSVGWrapper />
              </p>
            )}
            {currentMessage.loading && <Progress />}
          </PopUp>
        </div>
      )}
    </>
  );
};

export default CollectionsPage;
