export interface Message {
  code: number;
  title: string;
  description: string;
  metaMaskLogo?: boolean;
  loading?: boolean;
}

export const WALLET_IS_NOT_CONNECTED: Message = {
  code: 1,
  title: 'Oops. Your wallet is not connected.',
  description:
    'Please make sure that your MetaMask wallet is connected and available. In order to download this exclusive collection of 3D objects you need to hold one of the 1000 NFTs from the giveaway in your MetaMask wallet.',
};

export const CAN_NOT_VERIFY_YOU: Message = {
  code: 2,
  title: 'Oops. We can’t verify you.',
  description:
    'We can’t verify that your MetaMask wallet holds one of the exclusive Lowe’s NFTs from the Airdrop. In order to download this exclusive collection of 3D objects you need to hold one of the 1000 NFTs from the contest in your MetaMask wallet.',
};

export const CAN_NOT_FIND_YOUR_NFT: Message = {
  code: 3,
  title: 'Oops. We can’t find your NFT.',
  description:
    'In order to download this exclusive collection of 3D objects you need to hold one of the 1000 NFTs from the contest in your MetaMask wallet. If you sold or traded that NFT you aren’t eligible for this download.',
};

export const SOMETHING_WENT_WRONG: Message = {
  code: 4,
  title: 'Oops. Something went wrong.',
  description: 'Please try again later.',
};

export const CONFIRMING_WALLET: Message = {
  code: 5,
  title: 'Confirming Wallet',
  description: 'Connecting your wallet and confirming NFT…',
  metaMaskLogo: true,
};
