import React from 'react';
import styled from 'styled-components';
import screens from '../../@style/screens';
import CloseSVG from '../../images/close.svg';
import PlayButtonSVG from '../../images/play-button.svg';
import { MIDNIGHT_BLUE } from '../../@style/colors';
import ReactPlayer from 'react-player';

export interface Props {
  url: string;
  onClose: () => void;
}

const StyledPopUp = styled.div`
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  z-index: 50;
  bottom: 0;
  left: 0;
  top: 0;
  position: fixed;
  background: ${MIDNIGHT_BLUE};
`;

const GeneralContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
`;

const VideoWrapper = styled.div`
  display: flex;
  text-align: center;
  padding: 30px 0;
  @media ${screens.laptop} {
    padding: 30px;
  }
`;

const VideoContainer = styled.div`
  height: 176px;
  width: 310px;
  border-radius: 20px;
  @media ${screens.laptop} {
    height: 539px;
    width: 956px;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: right;
`;

const CloseButton = styled(CloseSVG)`
  padding: -20px 0 0 0;
  cursor: pointer;
  line,
  path {
    stroke: white;
  }
  width: 18px;
  height: 18px;
  @media ${screens.mobileL} {
    width: 14px;
    height: 14px;
  }
`;

const PlayButton = styled(PlayButtonSVG)`
  width: 35px;
  height: 35px;
  @media ${screens.tablet} {
    width: 80px;
    height: 80px;
  }
`;

const VideoPlayer: React.FC<Props> = ({ url, onClose }) => {
  return (
    <StyledPopUp>
      <GeneralContainer>
        <Header>
          <CloseButton onClick={onClose} />
        </Header>
        <VideoWrapper>
          <VideoContainer>
            <ReactPlayer
              width="100%"
              height="100%"
              url={url}
              playing
              playIcon={<PlayButton />}
              light
            />
          </VideoContainer>
        </VideoWrapper>
      </GeneralContainer>
    </StyledPopUp>
  );
};

export default VideoPlayer;
