import { verify } from 'web3-token';
import { Asset } from '../constants/assets';
import { User, UserKey } from '../constants/auth';
import { getAssets } from './getAssets.service';

export const isBrowser = (): boolean => typeof window !== 'undefined';

const setUser = (user: User | '') => window.localStorage.setItem(UserKey, JSON.stringify(user));

export const logout = (callback?: () => void): void => {
  setUser('');
  callback?.();
};

export const getUserSignature = (): string => {
  if (isBrowser() && window.localStorage.getItem(UserKey)) {
    return (JSON.parse(window.localStorage.getItem(UserKey) as string) as User).signature;
  }
  return '';
};

const verifySession = () => {
  const signature = getUserSignature();
  if (signature) {
    try {
      verify(signature);
    } catch (error) {
      logout();
    }
  }
};

export const getUser = (): User | null => {
  verifySession();
  if (isBrowser() && window.localStorage.getItem(UserKey)) {
    return JSON.parse(window.localStorage.getItem(UserKey) as string) as User;
  }
  return null;
};

export const getAssetsData = (): Asset[] | undefined | null => {
  verifySession();
  if (isBrowser() && window.localStorage.getItem(UserKey)) {
    return (JSON.parse(window.localStorage.getItem(UserKey) as string) as User).assets;
  }
  return null;
};

export const getUserMMAccount = (): string => {
  verifySession();
  if (isBrowser() && window.localStorage.getItem(UserKey)) {
    return (JSON.parse(window.localStorage.getItem(UserKey) as string) as User).account;
  }
  return '';
};

export const handleLogin = async (account: string, signature: string): Promise<void> => {
  if (!account || !signature) {
    return;
  }

  const assets = await getAssets(signature);
  if (!Array.isArray(assets)) {
    throw assets;
  }

  setUser({
    account: account,
    signature: signature,
    assets: assets,
  });
};

export const isLoggedIn = (): boolean => {
  const user = getUser();
  return !!(user as User)?.signature;
};
