import React from 'react';
import styled, { CSSProperties } from 'styled-components';
import screens from '../@style/screens';
import OnboardingButton from './ui/onboardingButton';
import { personalSign } from '../api/personalSign.service';
import { handleLogin } from '../api/auth.service';
import { CAN_NOT_FIND_YOUR_NFT, Message } from '../constants/messages';
import { BrowserView, MobileView } from 'react-device-detect';

interface RedemptionFrameProps {
  isLoggedUser: boolean;
  description: string;
  setCurrentMessage: (currentMessage: Message) => void;
  setShowPopUp: (showPopUp: boolean) => void;
  setIsLoggedUser: () => void;
}

// styles
const Wrapper = styled.div`
  display: flex;
  padding-top: 2rem;
  align-items: center;
  text-align: center;
  flex-direction: column;
  justify-content: space-between;
  @media ${screens.tablet} {
    padding-top: 3rem;
  }
`;

const Title = styled.h1`
  font-size: 26px;
  text-align: center;
  font-weight: bold;
  margin-botton: 30px;
  @media ${screens.tablet} {
    font-size: 40px;
  }
`;

const Description = styled.p`
  width: 96%;
  font-size: 17px;
  text-align: center;
  font-weight: 400;
  margin: 20px 0;
  align-self: center;
  @media ${screens.tablet} {
    width: 65%;
    font-size: 20px;
    margin: 30px 0 15px 0;
  }
`;

const styles: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
};

const RedemptionFrame: React.FC<RedemptionFrameProps> = ({
  isLoggedUser,
  description,
  setCurrentMessage,
  setShowPopUp,
  setIsLoggedUser,
}) => {
  const handleAuthFlow = async (userAccounts: string[]) => {
    if (userAccounts.length === 0) {
      return;
    }

    await personalSign()
      .then((signature) => {
        return handleLogin(userAccounts[0], signature);
      })
      .then(() => {
        setShowPopUp(false);
        setIsLoggedUser();
      })
      .catch((error) => {
        setCurrentMessage(CAN_NOT_FIND_YOUR_NFT);
        setShowPopUp(true);
      });
  };

  return (
    <>
      <Wrapper>
        <Title>Lowe’s Exclusive NFT Airdrop is Complete</Title>
        <BrowserView style={styles}>
          <Description>{description}</Description>
          <OnboardingButton
            isLoggedUser={isLoggedUser}
            onAccountChange={handleAuthFlow}
            logoutAssets={setIsLoggedUser}
            setCurrentMessage={setCurrentMessage}
            setShowPopUp={setShowPopUp}
          />
        </BrowserView>
        <MobileView style={styles}>
          <Description>
            The airdrop is over. If you claimed one of our NFTs, you can use it to access the
            exclusive Decentraland collections below. However, downloading on mobile devices is
            currently not supported. Please use a desktop device or laptop to get started.
          </Description>
        </MobileView>
      </Wrapper>
    </>
  );
};

export default RedemptionFrame;
