import { downloadAsset } from '../api/downloadAsset.service';

const getFileChunckPromisesArray = (
  size: number,
  assetId: string,
  signature: string
): Promise<any>[] => {
  const requests: Promise<any>[] = [];
  if (!size || typeof size !== 'number') {
    return requests;
  }

  const eachFileChunk = 5000000;

  for (let request = 0; request < Math.ceil(size / eachFileChunk); request++) {
    requests.push(
      downloadAsset(
        assetId,
        signature,
        false,
        request * eachFileChunk,
        (request + 1) * eachFileChunk - 1
      )
    );
  }

  return requests;
};

export default getFileChunckPromisesArray;
