import React from 'react';
import styled, { keyframes } from 'styled-components';
import screens from '../../@style/screens';

const loadingAnimation = keyframes`
 0% { width: 0%; }
 25% { width: 25%; }
 50% { width: 50%; }
 75% { width: 75%; }
 100% { width: 100%; }`;

// styles
const Wrapper = styled.div`
  display: flex;
  position: relative;
  align-self: center;
  background-color: #84f2fc;
  width: 197px;
  height: 23px;
  border-radius: 15px;
  margin-top: 20px;
  @media ${screens.tablet} {
    width: 220px;
    height: 25px;
    border-radius: 15px;
  }
`;

const Animation = styled.div`
  position: absolute;
  background-color: #4cbefc;
  width: 0px;
  height: 23px;
  border-radius: 15px;
  animation: ${loadingAnimation} 1.5s infinite linear;
  @media ${screens.tablet} {
    height: 25px;
    border-radius: 15px;
  }
`;

const Progress: React.FC = () => (
  <Wrapper>
    <Animation />
  </Wrapper>
);

export default Progress;
