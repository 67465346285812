import React, { ReactNode } from 'react';
import styled from 'styled-components';
import screens from '../../@style/screens';
import CloseSVG from '../../images/close.svg';
import useOutsideClickClose from '../../hooks/useOutsideClickClose';
import { CERULEAN, MIDNIGHT_BLUE } from '../../@style/colors';

export interface Props {
  title: string;
  children: ReactNode;
  onClose: () => void;
}

const StyledPopUp = styled.div`
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  z-index: 50;
  bottom: 0;
  left: 0;
  top: 0;
  position: fixed;
  background-color: hsla(0, 0%, 43%, 0.5);
`;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  width: 65vw;
  background: white;
  color: ${MIDNIGHT_BLUE};
  text-align: center;
  border-radius: 20px;
  border: 2px solid ${CERULEAN};
  padding: 30px;
  @media ${screens.tablet} {
    width: 500px;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: right;
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin: 20px 0;
  @media ${screens.mobileL} {
    margin: 15px 0;
  }
`;

const CloseButton = styled(CloseSVG)`
  display: flex;
  justify-content: right;
  cursor: pointer;
  line,
  path {
    stroke: black;
  }
  width: 12px;
  height: 12px;
  @media ${screens.mobileL} {
    width: 14px;
    height: 14px;
  }
`;

const PopUp: React.FC<Props> = ({ title, children, onClose }) => {
  const outsideClickCloseRef = useOutsideClickClose(onClose);

  return (
    <StyledPopUp>
      <StyledContainer ref={outsideClickCloseRef}>
        <Header>
          <CloseButton onClick={onClose} />
        </Header>
        <Title>{title}</Title>
        {children}
      </StyledContainer>
    </StyledPopUp>
  );
};

export default PopUp;
