import MetaMaskOnboarding from '@metamask/onboarding';
import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import screens from '../../@style/screens';
import { logout } from '../../api/auth.service';
import Button from './button';
import MetaMaskSVG from '../../images/metamask-logo.svg';
import { CAN_NOT_VERIFY_YOU, CONFIRMING_WALLET, Message } from '../../constants/messages';

const ONBOARD_TEXT = 'Install MetaMask';
const CONNECT_TEXT = 'Sign In With MetaMask';
const CONNECTED_TEXT = 'Sign Out';

interface OnboardingProps {
  isLoggedUser: boolean;
  onAccountChange: (newAccounts: string[]) => void;
  logoutAssets: () => void;
  setCurrentMessage: (currentMessage: Message) => void;
  setShowPopUp: (showPopUp: boolean) => void;
}

// styles
const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px 0;
  align-self: center;
`;

const MetaMaskSVGWrapper = styled(MetaMaskSVG)`
  width: 20px;
  height: 20px;
  margin-right: 5px;
  @media ${screens.tablet} {
    width: 18px;
    height: 18px;
  }
`;

const OnboardingButton: React.FC<OnboardingProps> = ({
  isLoggedUser,
  onAccountChange,
  logoutAssets,
  setCurrentMessage,
  setShowPopUp,
}) => {
  const [buttonText, setButtonText] = useState(ONBOARD_TEXT);
  const onboarding = useRef<MetaMaskOnboarding>();

  useEffect(() => {
    if (!onboarding.current) {
      onboarding.current = new MetaMaskOnboarding();
    }
  }, []);

  useEffect(() => {
    if (MetaMaskOnboarding.isMetaMaskInstalled()) {
      setButtonText(isLoggedUser ? CONNECTED_TEXT : CONNECT_TEXT);
    }
  }, [isLoggedUser]);

  const handleOnClick = () => {
    if (isLoggedUser) {
      logout();
      logoutAssets();
      setButtonText(CONNECT_TEXT);
      return;
    }

    if (!MetaMaskOnboarding.isMetaMaskInstalled()) {
      onboarding.current?.startOnboarding();
      return;
    }

    setCurrentMessage(CONFIRMING_WALLET);
    setShowPopUp(true);
    window.ethereum
      .request({ method: 'eth_requestAccounts' })
      .then((newAccounts: string[]) => {
        onAccountChange(newAccounts);
      })
      .catch((error: unknown) => {
        setCurrentMessage(CAN_NOT_VERIFY_YOU);
        setShowPopUp(true);
      });
  };

  return (
    <>
      <ButtonWrapper>
        <Button type="internal" buttonStyle="fit" onClick={handleOnClick}>
          <MetaMaskSVGWrapper />
          {buttonText}
        </Button>
      </ButtonWrapper>
    </>
  );
};

export default OnboardingButton;
