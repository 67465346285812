import React from 'react';
import styled from 'styled-components';
import screens from '../../@style/screens';

interface Props {
  children?: React.ReactNode;
}

const GridWrapper = styled.section<Props>`
  display: grid;
  column-gap: 1rem;
  row-gap: 2rem;
  margin: 15px 0;
  grid-template-columns: repeat(1, 1fr);
  @media ${screens.laptop} {
    margin: 30px 0;
    column-gap: 1.7rem;
    row-gap: 5rem;
    grid-template-columns: repeat(2, 1fr);
  }
  @media ${screens.laptopXL} {
    row-gap: 6rem;
  }
`;

const Grid: React.FC<Props> = ({ children }) => {
  return <GridWrapper>{children}</GridWrapper>;
};

export default Grid;
